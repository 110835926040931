<!--
TEMPLATE
-->
<template>
    <b-container class="d-flex align-items-center p-4 bg-light" style="min-height: 100vh" fluid>
        <div class="m-auto" style="width: 22rem">

            <!--
            BOX
            -->
            <b-card class="shadow rounded bg-white" no-body fluid>

                <!--
                HEADER
                -->
                <b-card-header class="bg-light d-flex">
                    <h5 class="text-secondary mb-0 mr-2">{{ tenant_label }} / {{ isLogin() ? 'Login' : 'Signup' }}</h5>
                    <b-button v-if="hasFocus()" class="ml-auto pt-0" style="height: 25px" size="sm" variant="outline-danger" v-on:click="clearFocus(true)" :disabled="loading"><small>Return</small></b-button>
                    <b-button v-else-if="hasSession()" class="ml-auto pt-0" style="height: 25px" size="sm" variant="outline-danger" v-on:click="resetMode()" :disabled="loading"><small>{{ isLogin() ? 'Logout' : 'Restart' }}</small></b-button>
                    <b-button v-else class="ml-auto pt-0" style="height: 25px" size="sm" variant="outline-danger" v-on:click="cancel()" :disabled="loading"><small>Cancel</small></b-button>
                </b-card-header>

                <!--
                BODY
                -->
                <b-card-body>

                    <!--
                    LOGO
                    -->
                    <b-row class="mx-0">
                        <b-col class="text-center p-0">
                            <b-img :src="tenant_logo" height="80px" :style="'max-width: 300px' + ((!isRoot() && tenant_logo.includes(tenant_id)) ? '' : `;filter: ${getFilter('primary')}`)"></b-img>
                        </b-col>
                    </b-row>

                    <div class="pt-2" v-if="loading">

                        <!--
                        LOADING
                        -->
                        <b-row class="pb-2 mx-0">
                            <b-col class="text-center p-5">
                                <b-spinner variant="primary"></b-spinner>
                                <p><small class="text-primary">{{ loading }}</small></p>
                            </b-col>
                        </b-row>

                    </div>
                    <div class="pt-2" v-else-if="error">

                        <!--
                        ERROR
                        -->
                        <b-row class="pb-2 mx-0">
                            <b-col class="text-center p-5">
                                {{ error }}
                            </b-col>
                        </b-row>

                    </div>
                    <div class="pt-2" v-else-if="!loading">

                        <!--
                        PROGRESS
                        -->
                        <div v-if="getScore() || (hasFocus() && factor.score)">
                            <b-row class="pb-2 mx-0">
                                <b-col class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-50">
                                    <span class="w-50 mb-1">progress</span>
                                    <hr class="w-50">
                                </b-col>
                            </b-row>
                            <b-row class="pb-2 mx-0">
                                <b-col class="p-0">
                                    <b-progress :max="goal" height="25px">
                                        <b-progress-bar v-if="hasFocus()" :value="getScore() + factor.score" :variant="getScoreVariant(factor.score)" animated>
                                            <span v-if="options.includes('show_scores')">{{ getScore() + factor.score }} / {{ goal }}</span>
                                        </b-progress-bar>
                                        <b-progress-bar v-else :value="getScore()" :variant="getScoreVariant(0)">
                                            <span v-if="options.includes('show_scores')">{{ getScore() }} / {{ goal }}</span>
                                        </b-progress-bar>
                                    </b-progress>
                                </b-col>
                            </b-row>
                        </div>

                        <!--
                        INSTRUCTIONS
                        -->
                        <div v-if="output" >
                            <b-row class="pb-2 mx-0">
                                <b-col v-if="this.output.factor.subtype === 'totp'" class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">scan QR code</span>
                                    <hr class="w-75">
                                </b-col>
                                <b-col v-else-if="this.output.factor.subtype === 'secret:password'" class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-50">
                                    <span class="w-100 mb-1">save {{ this.output.factor.label.toLowerCase() }}</span>
                                    <hr class="w-50">
                                </b-col>
                                <b-col v-else-if="this.output.factor.subtype === 'jwt:spki'" class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">save key pair</span>
                                    <hr class="w-75">
                                </b-col>
                                <!-- jwt:bearer -->
                                <b-col v-else class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">save token</span>
                                    <hr class="w-75">
                                </b-col>
                            </b-row>
                        </div>
                        <div v-else-if="consent">
                            <b-row class="pb-2 mx-0">
                                <b-col class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">give consent</span>
                                    <hr class="w-75">
                                </b-col>
                            </b-row>
                        </div>
                        <div v-else-if="hasFocus()">
                            <b-row v-if="this.factor.type === 'enrollment'" class="pb-2 mx-0">
                                <b-col v-if="this.factor.subtype.startsWith('secret')" class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-50">
                                    <span class="w-100 mb-1">{{ isLogin() ? 'provide' : 'confirm' }} {{ this.factor.label.toLowerCase() }}</span>
                                    <hr class="w-50">
                                </b-col>
                                <b-col v-else-if="this.factor.subtype === 'otp'" class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-50">
                                    <span class="w-100 mb-1">{{ isLogin() ? 'provide' : 'confirm' }} password</span>
                                    <hr class="w-50">
                                </b-col>
                                <b-col v-else-if="this.factor.subtype === 'totp'" class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-50">
                                    <span class="w-100 mb-1">{{ isLogin() ? 'provide' : 'confirm' }} password</span>
                                    <hr class="w-50">
                                </b-col>
                                <b-col v-else-if="this.factor.subtype === 'jwt:spki'" class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">{{ isLogin() ? 'provide' : 'confirm' }} key</span>
                                    <hr class="w-75">
                                </b-col>
                                <b-col v-else-if="this.factor.subtype === 'jwt:jwks'" class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">{{ isLogin() ? 'provide' : 'confirm' }} key</span>
                                    <hr class="w-75">
                                </b-col>
                                <!-- jwt:bearer -->
                                <b-col v-else class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">{{ isLogin() ? 'provide' : 'confirm' }} token</span>
                                    <hr class="w-75">
                                </b-col>
                            </b-row>
                            <!-- factor -->
                            <b-row v-else class="pb-2 mx-0">
                                <b-col v-if="this.factor.subtype.startsWith('secret')" class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-50">
                                    <span class="w-100 mb-1">provide {{ this.factor.label.toLowerCase() }}</span>
                                    <hr class="w-50">
                                </b-col>
                                <b-col v-else-if="this.factor.subtype === 'otp'" class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-50">
                                    <span class="w-100 mb-1">provide channel</span>
                                    <hr class="w-50">
                                </b-col>
                                <b-col v-else-if="this.factor.subtype === 'jwt:spki'" class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">provide key</span>
                                    <hr class="w-75">
                                </b-col>
                                <b-col v-else-if="this.factor.subtype === 'jwt:jwks'" class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">provide URL</span>
                                    <hr class="w-75">
                                </b-col>
                                <!-- jwt:bearer -->
                                <b-col v-else class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">provide token</span>
                                    <hr class="w-75">
                                </b-col>
                            </b-row>
                        </div>
                        <div v-else-if="hasFactors(f => f)">
                            <b-row class="pb-2 mx-0">
                                <b-col class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-50">
                                    <span class="w-50 mb-1">{{ getMode() }} with</span>
                                    <hr class="w-50">
                                </b-col>
                            </b-row>
                        </div>
                        <!-- INVITE -->
                        <div v-else-if="isSignup() && !hasSession()">
                            <b-row class="pb-2 mx-0">
                                <b-col class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">provide token</span>
                                    <hr class="w-75">
                                </b-col>
                            </b-row>
                        </div>
                        <div v-else>
                            <b-row class="pb-2 mx-0">
                                <b-col class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">dead end</span>
                                    <hr class="w-75">
                                </b-col>
                            </b-row>
                            <b-row class="pb-2 mx-0">
                                <b-col class="text-center p-0">
                                    <small>Seems you can't proceed as you have no more options left. Please contact your administrator.</small>
                                </b-col>
                            </b-row>
                        </div>

                        <!--
                        OUTPUT
                        -->
                        <div v-if="output">

                            <!--
                            OUTPUT
                            -->
                            <b-row class="pb-2 mx-0">
                                <b-col class="text-center p-0">
                                    <b-img v-if="output.factor.subtype === 'totp'" :src="output.value.image"></b-img>
                                    <b-form-textarea v-else v-model="output.value" :readonly="true" size="sm" max-rows="5" no-resize></b-form-textarea>
                                </b-col>
                            </b-row>

                            <!--
                            CONTINUE
                            -->
                            <b-row class="pb-2 mx-0">
                                <b-col class="p-0">
                                    <b-button v-if="output.factor.subtype === 'totp'" v-on:click="clearOutput()" variant="primary" class="w-100">Setup Complete</b-button>
                                    <b-button v-else-if="output.factor.subtype === 'jwt:spki'" v-on:click="saveOutput()" variant="primary" class="w-100">Download Key Pair</b-button>
                                    <b-button v-else-if="output.factor.subtype === 'jwt:bearer'" v-on:click="saveOutput()" variant="primary" class="w-100">Download Personal Token</b-button>
                                    <b-button v-else v-on:click="saveOutput()" variant="primary" class="w-100">Copy {{ output.factor.label }}</b-button>
                                </b-col>
                            </b-row>

                        </div>

                        <!--
                        CONSENT
                        -->
                        <div v-else-if="consent">

                            <!--
                            CONTROLS
                            -->
                            <b-row v-for="control in filterControls(c => c.consent_required && c.score <= getScore())" v-bind:key="control.id" class="pb-2 mx-0">
                                <b-col class="p-0">
                                    <b-form-checkbox :name="control.label" :required="control.required" :state="control.required ? control.consent : null" v-model="control.consent">
                                        <small v-if="control.subtype === 'legal'">I accept the <a :href="control.value" target="_blank">{{ control.label }}</a>{{ control.required ? ' *': '' }}</small>
                                        <small v-else>I grant <a :href="control.value" target="_blank">{{ control.label }}</a> to <br/>{{ client_label || client_id }}{{ control.required ? ' *': '' }}</small>
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>

                            <!--
                            SAVE
                            -->
                            <b-row class="pb-2 mx-0">
                                <b-col class="p-0">
                                    <b-button variant="primary" :disabled="hasControls(c => c.score <= getScore() && c.required && c.consent_required && !c.consent)" v-on:click="postControls(true)" class="w-100">Save Consent</b-button>
                                </b-col>
                            </b-row>

                            <!--
                            OR
                            -->
                            <b-row v-if="!hasSession() && isSignup()" class="pb-2 mx-0">
                                <b-col class="d-flex text-center align-items-center h-100 p-0">
                                    <hr class="w-100">
                                    <span class="w-50 mb-1">or</span>
                                    <hr class="w-100">
                                </b-col>
                            </b-row>

                            <!--
                            INVITE TOKEN
                            -->
                            <b-row v-if="!hasSession() && isSignup()" class="pb-2 mx-0">
                                <b-col class="p-0">
                                    <b-input-group class="w-100" style="max-width:100%">
                                        <b-form-input v-model="token" :autofocus="true" type="text" name="token" :state="checkToken()" placeholder="Invite Token" v-on:keyup.enter="useToken()"></b-form-input>
                                        <b-input-group-append>
                                            <b-button variant="primary" :disabled="!checkToken()" v-on:click="useToken()">Continue</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                            </b-row>

                        </div>
                        
                        <!--
                        FACTORS
                        -->
                        <div v-else>

                            <!--
                            LIST
                            -->
                            <div v-if="!hasFocus()">

                                <!--
                                OAUTH2
                                -->
                                <b-row v-if="options.includes('highlight_identity_providers') && hasFactors(f => f.subtype.startsWith('oauth2'))" class="pb-0 mx-0">
                                    <b-col v-for="factor in filterFactors(f => f.subtype.startsWith('oauth2'))" v-bind:key="factor.id" class="text-center pl-0 pb-3 m-auto" style="min-width: 45px; max-width: 45px; min-height: 45px;">
                                        <b-img :src="`/img/factors/${factor.subtype.replace(':','/')}.svg`" v-on:click="selectFactor(factor)" v-b-tooltip.hover :title="factor.label" height="40px" width="40px" style="cursor: pointer"></b-img>
                                        <b-badge v-if="options.includes('show_scores') || (options.includes('highlight_one_step_options') && reachesGoal(factor.score))" variant="primary" style="position: absolute; right: 0px; bottom: 10px">{{ options.includes('show_scores') ? factor.score : '' }}<b-img v-if="options.includes('highlight_one_step_options') && reachesGoal(factor.score)" src="/img/icons/star.svg" :style="`filter: ${getFilter('white')}; margin-top: -3px; margin-right: -0.5px`" height="10px" width="10px"></b-img></b-badge>
                                    </b-col>
                                </b-row>

                                <!--
                                OR
                                -->
                                <b-row v-if="options.includes('highlight_identity_providers') && hasFactors(f => f.subtype.startsWith('oauth2')) && hasFactors(f => !f.subtype.startsWith('oauth2'))" class="pb-2 mx-0">
                                    <b-col class="d-flex text-center align-items-center h-100 p-0">
                                        <hr class="w-100">
                                        <span class="w-50 mb-1">or</span>
                                        <hr class="w-100">
                                    </b-col>
                                </b-row>

                                <!--
                                OTHERS
                                -->
                                <b-row v-for="factor in filterFactors(f => !options.includes('highlight_identity_providers') || !f.subtype.startsWith('oauth2'))" v-bind:key="factor.id" class="pb-2 mx-0">
                                    <b-col class="p-0">
                                        <b-input-group v-if="isLogin() && countFactors(f => !f.subtype.startsWith('oauth2')) === 1 && factor.regex" class="w-100" style="max-width:100%">
                                            <b-form-input v-model="factor.input" :autofocus="true" :autocomplete="getAutoComplete(factor)" :type="getInputType(factor)" :name="getInputName(factor)" :state="checkInput(factor)" :placeholder="factor.label" v-on:keyup.enter="selectFactor(factor)"></b-form-input>
                                            <b-input-group-append>
                                                <b-button :variant="getVariant(factor)" :disabled="!isEnabled(factor) || !checkInput(factor)" v-on:click="selectFactor(factor)">Continue</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <b-button-group v-else class="w-100" v-b-popover.hover.right="getInfo(factor)">
                                            <b-button :variant="getVariant(factor)" :disabled="!isEnabled(factor)" style="width: 50px"><b-img :src="`/img/factors/${factor.subtype.replace(':','/')}.svg`" :style="`filter: ${getFilter('white')}; margin-top: -3px;`" height="20px" width="20px"></b-img></b-button>
                                            <b-button :variant="`outline-${getVariant(factor)}`" :disabled="!isEnabled(factor)" v-on:click="selectFactor(factor)" class="w-100">{{ factor.label }}</b-button>
                                            <b-badge v-if="options.includes('show_scores') || (options.includes('highlight_one_step_options') && reachesGoal(factor.score))" variant="primary" style="position: absolute; right: -5px; bottom: 9px; z-index: 1">{{ options.includes('show_scores') ? factor.score : '' }}<b-img v-if="options.includes('highlight_one_step_options') && reachesGoal(factor.score)" src="/img/icons/star.svg" :style="`filter: ${getFilter('white')}; margin-top: -3px; margin-right: -0.5px`" height="10px" width="10px"></b-img></b-badge>
                                        </b-button-group>
                                    </b-col>
                                </b-row>

                                <!--
                                OR
                                -->
                                <b-row v-if="!hasSession() && isSignup() && hasFactors(f => f)" class="pb-2 mx-0">
                                    <b-col class="d-flex text-center align-items-center h-100 p-0">
                                        <hr class="w-100">
                                        <span class="w-50 mb-1">or</span>
                                        <hr class="w-100">
                                    </b-col>
                                </b-row>

                                <!--
                                INVITE TOKEN
                                -->
                                <b-row v-if="!hasSession() && isSignup()" class="pb-2 mx-0">
                                    <b-col class="p-0">
                                        <b-input-group class="w-100" style="max-width:100%">
                                            <b-form-input v-model="token" :autofocus="true" type="text" name="token" :state="checkToken()" placeholder="Invite Token" v-on:keyup.enter="useToken()"></b-form-input>
                                            <b-input-group-append>
                                                <b-button variant="primary" :disabled="!checkToken()" v-on:click="useToken()">Continue</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-row>

                            </div>

                            <!--
                            FOCUS
                            -->
                            <div v-else>

                                <!--
                                INPUT
                                -->
                                <b-row v-for="factor in filterFactors(f => f)" v-bind:key="factor.id" class="pb-2 mx-0">
                                    <b-col class="p-0">
                                        <b-input-group class="w-100" style="max-width:100%">
                                            <b-form-file v-if="factor.subtype === 'jwt:bearer'" v-model="factor.input" :autofocus="true" :state="checkInput(factor)" accept=".jwt" :placeholder="factor.label"></b-form-file>
                                            <b-form-file v-else-if="(factor.subtype === 'jwt:spki') || (factor.subtype === 'jwt:jwks' && factor.type === 'enrollment')" v-model="factor.input" :autofocus="true" :state="checkInput(factor)" accept=".pem" :placeholder="factor.label"></b-form-file>
                                            <b-form-input v-else v-model="factor.input" :autofocus="true" :autocomplete="getAutoComplete(factor)" :type="getInputType(factor)" :name="getInputName(factor)" :state="checkInput(factor)" :placeholder="factor.label" v-on:keyup.enter="selectFactor(factor)"></b-form-input>
                                            <b-input-group-append>
                                                <b-button :variant="getVariant(factor)" :disabled="!isEnabled(factor) || !checkInput(factor)" v-on:click="selectFactor(factor)">Continue</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-row>

                                <!--
                                OR
                                -->
                                <b-row v-if="isSignup() && this.factor.type !== 'enrollment' && ['secret:password','jwt:spki'].includes(this.factor.subtype)" class="pb-2 mx-0">
                                    <b-col class="d-flex text-center align-items-center h-100 p-0">
                                        <hr class="w-100">
                                        <span class="w-50 mb-1">or</span>
                                        <hr class="w-100">
                                    </b-col>
                                </b-row>

                                <!--
                                GENERATE
                                -->
                                <b-row v-if="isSignup() && this.factor.type !== 'enrollment' && ['secret:password','jwt:spki'].includes(this.factor.subtype)" class="pb-2 mx-0">
                                    <b-col class="p-0">
                                        <b-button v-if="this.factor.subtype === 'secret:password'" variant="primary" v-on:click="selectFactor(this.factor, true)" class="w-100">Generate {{ factor.label }}</b-button>
                                        <b-button v-else variant="primary" v-on:click="selectFactor(this.factor, true)" class="w-100">Generate Key Pair</b-button>
                                    </b-col>
                                </b-row>

                            </div>
                        </div>

                        <!--
                        INSTRUCTIONS
                        -->
                        <div v-if="output" >
                            <b-row class="pb-2 mx-0">
                                <b-col class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">instructions</span>
                                    <hr class="w-75">
                                </b-col>
                            </b-row>
                            <b-row class="pb-2 mx-0">
                                <b-col class="text-center p-0">
                                    <small v-if="output.factor.subtype === 'totp'">Open your authenticator app, and scan above QR code. Alternatively you can manually enter this setup key [ <small><b>{{ output.value.secret }}</b></small> ]. When asked, use time-based type and use this label<br/>[ <small><b>{{ getAccount() }}</b></small> ].</small>
                                    <small v-else-if="output.factor.subtype === 'jwt:spki'">We've generated the above key pair for you. This is the only time you will be able to obtain it in clear so please make sure to save it now.</small>
                                    <small v-else-if="output.factor.subtype === 'jwt:bearer'">We've generated above personal token for you. This is the only time you will be able to obtain it in clear so please make sure to save it now. Please note the token is only <b>valid for 1 year</b>.</small>
                                    <small v-else>We've generated the above {{ output.factor.label.toLowerCase() }} for you. This is the only time you will be able to obtain it in clear so please make sure to save it now.</small>
                                </b-col>
                            </b-row>
                        </div>
                        <div v-else-if="consent">
                            <b-row class="pb-2 mx-0">
                                <b-col class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">instructions</span>
                                    <hr class="w-75">
                                </b-col>
                            </b-row>
                            <b-row class="pb-2 mx-0">
                                <b-col class="text-center p-0">
                                    <small v-if="!hasSession() && isSignup()">We ask for your consent in order to proceed. Please review every item and consent where desired - required consents are marked [ * ]. Alternatively if you received an invite token it will be best to proceed by using it here now.</small>
                                    <small v-else>We ask for your consent in order to proceed. Please review every item and consent where desired - required consents are marked [ * ].</small>
                                </b-col>
                            </b-row>
                        </div>
                        <div v-else-if="hasFocus()">
                            <b-row v-if="(!this.factor.subtype.startsWith('secret') || (this.factor.subtype === 'secret:password' && isSignup() && this.factor.type === 'factor')) && (this.factor.subtype !== 'jwt:bearer')" class="pb-2 mx-0">
                                <b-col class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">instructions</span>
                                    <hr class="w-75">
                                </b-col>
                            </b-row>
                            <b-row v-if="this.factor.subtype === 'secret:password' && isSignup() && this.factor.type === 'factor'" class="pb-2 mx-0">
                                <b-col class="text-center p-0">
                                    <small>Choose your {{ factor.label.toLowerCase() }}. If the box outlines red please choose another one as it doesn't meet our requirements. Alternatively you can let us generate one for you, though this may not be possible to memorize (and you can't choose).</small>
                                </b-col>
                            </b-row>
                            <b-row v-else-if="this.factor.subtype === 'otp'" class="pb-2 mx-0">
                                <b-col v-if="this.factor.type === 'factor'" class="text-center p-0">
                                    <small>Provide where to receive a one-time password.</small>
                                    <br/>
                                </b-col>
                                <b-col v-else class="text-center p-0">
                                    <small>Provide the one-time password once received. It normally arrives soon... but could also take a couple minutes. In case you don't receive it go back and just try again, or pick another option.</small>
                                </b-col>
                            </b-row>
                            <b-row v-else-if="this.factor.subtype === 'totp'" class="pb-2 mx-0">
                                <b-col class="text-center p-0">
                                    <small>Open your authenticator app, and provide the one-time password as generated for the entry<br/>[ <small><b>{{ getAccount() }}</b></small> ].</small>
                                </b-col>
                            </b-row>
                            <b-row v-else-if="this.factor.subtype === 'jwt:spki' || this.factor.subtype === 'jwt:jwks'" class="pb-2 mx-0">
                                <b-col v-if="isSignup() && this.factor.type === 'factor'" class="text-center p-0">
                                    <small v-if="this.factor.subtype === 'jwt:spki'">Provide the <b>public key</b> that belongs to your private key by uploading it from your device (as a PEM-encoded SPKI file). Alternatively, you can let us generate a key pair for you.</small>
                                    <small v-else>Provide the URL hosting the <b>public keys</b> that belong to your private keys (in JWKS format). Please note they must be publicly accessible.</small>
                                </b-col>
                                <b-col v-else-if="this.factor.type === 'factor'" class="text-center p-0">
                                    <small v-if="this.factor.subtype === 'jwt:spki'">Provide the <b>public key</b> that belongs to your private key by uploading it from your device (as a PEM-encoded SPKI file).</small>
                                    <small v-else>Provide the URL hosting the <b>public keys</b> that belong to your private keys (in JWKS format).</small>
                                </b-col>
                                <b-col v-else class="text-center p-0">
                                    <small>Provide your private key by uploading it from your device (as a PEM-encoded PKCS8 file).</small>
                                </b-col>
                            </b-row>
                        </div>
                        <div v-else-if="hasFactors(f => f) && (options.includes('show_scores') || options.includes('highlight_one_step_options'))">
                            <b-row class="pb-2 mx-0">
                                <b-col class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-75">
                                    <span class="w-100 mb-1">instructions</span>
                                    <hr class="w-75">
                                </b-col>
                            </b-row>
                            <b-row class="pb-2 mx-0">
                                <b-col class="text-center p-0">
                                    <small v-if="hasSession()">Almost there! Choose an option to proceed. {{ options.includes('show_scores') ? 'Each option has a score indicating how quickly you\'ll be able to finish.' : '' }} {{ options.includes('highlight_one_step_options') ? 'Those with a star will take only 1 step.' : '' }}</small>
                                    <small v-else>Choose an option to {{ getMode() }}. {{ options.includes('show_scores') ? 'Each option has a score indicating how quickly you\'ll be able to finish.' : '' }} {{ options.includes('highlight_one_step_options') ? 'Those with a star will take only 1 step.' : '' }} {{ isSignup() ? 'Alternatively if you received an invite token it will be best to proceed by using it here now.' : '' }}</small>
                                </b-col>
                            </b-row>
                        </div>

                        <!--
                        TIMER
                        -->
                        <div v-if="hasSession() || (hasFocus() && factor.expires_at)">
                            <b-row class="pb-2 mx-0">
                                <b-col class="d-flex text-center h-100 align-items-center p-0">
                                    <hr class="w-50">
                                    <span class="w-50 mb-1">time left</span>
                                    <hr class="w-50">
                                </b-col>
                            </b-row>
                            <b-row class="pb-2 mx-0">
                                <b-col class="text-center p-0">
                                    <small v-if="timer.days || timer.hours || timer.minutes">
                                        <b v-if="timer.days"> {{ timer.days + (timer.days > 1 ? ' days ' : ' day ') }} </b>
                                        <b v-if="timer.hours"> {{ timer.hours + (timer.hours > 1 ? ' hours ' : ' hour ') }} </b>
                                        <b v-if="timer.minutes"> {{ timer.minutes + (timer.minutes > 1 ? ' minutes ' : ' minute ') }} </b>
                                        <b v-if="timer.seconds"> {{ timer.seconds + (timer.seconds > 1 ? ' seconds ' : ' second ') }} </b>
                                    </small>
                                    <small v-else class="text-danger">
                                        <b> {{ timer.seconds + (timer.seconds === 1 ? ' second ' : ' seconds ') }} </b>
                                    </small>
                                </b-col>
                            </b-row>
                        </div>

                        <!--
                        SWITCH / RESET
                        -->
                        <b-row class="pb-2 mx-0">
                            <b-col class="d-flex align-items-center h-100 p-0">
                                <hr class="w-100">
                            </b-col>
                        </b-row>
                        <b-row class="pb-2 mx-0">
                            <b-col v-if="hasFocus()" class="text-center p-0 w-100">
                                <small>Got stuck? <span class="text-primary" style="cursor: pointer" v-on:click="clearFocus(true)">Return</span></small>
                            </b-col>
                            <b-col v-else-if="!hasSession()" class="text-center p-0 w-100">           
                                <small v-if="isLogin()">Don't have an account? <span class="text-primary" style="cursor: pointer" v-on:click="switchMode()">Signup</span></small>
                                <small v-else>Already have an account? <span class="text-primary" style="cursor: pointer" v-on:click="switchMode()">Login</span></small>
                            </b-col>
                            <b-col v-else class="text-center p-0 w-100">
                                <small v-if="isLogin()">Not you? <span class="text-primary" style="cursor: pointer" v-on:click="resetMode()">Logout</span></small>
                                <small v-else>Something wrong? <span class="text-primary" style="cursor: pointer" v-on:click="resetMode()">Restart</span></small>
                            </b-col>
                        </b-row>

                    </div>
                </b-card-body>

                <!--
                FOOTER
                -->
                <b-card-footer class="bg-light d-flex">
                    <small class="text-muted">Accessing {{ client_label }}</small>
                    <b-img src="/img/icons/zero.svg" height="20px" width="17px" class="ml-auto" :style="`filter: ${getFilter(options.includes('highlight_identity_providers') ? 'dark' : 'secondary')}; cursor: pointer`" v-on:click="options.includes('highlight_identity_providers') ? options = options.filter(option => option !== 'highlight_identity_providers') : options.push('highlight_identity_providers')" v-b-tooltip.hover title="Highlight Identity Providers"></b-img>
                    <b-img src="/img/icons/one.svg" height="20px" width="17px" class="ml-1" :style="`filter: ${getFilter(options.includes('highlight_one_step_options') ? 'dark' : 'secondary')}; cursor: pointer`" v-on:click="options.includes('highlight_one_step_options') ? options = options.filter(option => option !== 'highlight_one_step_options') : options.push('highlight_one_step_options')" v-b-tooltip.hover title="Highlight 1-Step Options"></b-img>
                    <b-img src="/img/icons/two.svg" height="20px" width="17px" class="ml-1" :style="`filter: ${getFilter(options.includes('show_scores') ? 'dark' : 'secondary')}; cursor: pointer`" v-on:click="options.includes('show_scores') ? options = options.filter(option => option !== 'show_scores') : options.push('show_scores')" v-b-tooltip.hover title="Show Scores"></b-img>
                </b-card-footer>

            </b-card>

            <!--
            SYSTEM
            -->
            <b-row class="pt-2 mx-0">
                <b-col class="text-muted text-center p-0">
                    <small>
                        <small v-if="isRoot()">{{ getRelease() }} | &copy; Copyright {{ new Date().getFullYear() }} Quasr BV</small>
                        <small v-else>{{ getRelease() }} | Powered by <a :href="getWebsite()" target="_blank">Quasr</a></small>
                    </small>
                </b-col>
            </b-row>
    
        </div>
    </b-container>
</template>

<!--
SCRIPT
-->
<script>
/**
 * IMPORTS
 */
import axios from 'axios';
import QR from 'qrcode';
import { decodeJwt, importPKCS8, SignJWT } from 'jose';
import tinycolor from 'tinycolor2';
import { hexToCSSFilter } from 'hex-to-css-filter';

/**
 * CONFIGURATION
 */
const ENVIRONMENT = 'prod';
const BASIC_AUTHZ = '';
const UPDATE_DATE = '2025.01.15';
const ROOT_TENANT = 'b62a482d-7365-4ae9-85a5-1453b3b0d5b7';
const DOMAIN = ENVIRONMENT === 'prod' ? '.quasr.io' : `-${ENVIRONMENT}.quasr.io`;
const ID_REGEX = new RegExp('[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}');

/**
 * EXPORTS
 */
export default {
    
    /**
     * NAME
     */
    name: 'App',

    /**
     * DATA
     */
    data() {
        return {
            // TENANT
            tenant_id: ROOT_TENANT,
            // TENANT (LABEL)
            tenant_label: 'Quasr',
            // TENANT (LOGO)
            tenant_logo: (ENVIRONMENT === 'prod' ? `https://login.quasr.io/img/logos/${ROOT_TENANT}.png` : `https://login-${ENVIRONMENT}.quasr.io/img/logos/${ROOT_TENANT}.png`),
            // TENANT (COLOR)
            tenant_color: '#3c78d8',
            // CLIENT (ID)
            client_id: undefined,
            // CLIENT (LABEL)
            client_label: undefined,
            // SCOPES
            scopes: [],
            // LOADING
            loading: undefined,
            // CONTROLS
            controls: [],
            // FACTORS (ALL)
            factors: [],
            // FACTOR (FOCUS)
            factor: undefined,
            // GOAL
            goal: Number.MAX_VALUE,
            // CONSENT
            consent: false,
            // TIMER
            timer: { days: 0, hours: 0, minutes: 0, seconds: 0, timeout: undefined },
            // ERROR
            error: undefined,
            // TOKEN
            token: undefined,
            // OUTPUT
            output: undefined,
            // OPTIONS
            options: [ 'highlight_identity_providers', 'highlight_one_step_options' ]
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        this.loading = 'Initializing';
        var { id, input, prompt, error } = await this.initialize();
        if (!this.error) {
            if (!this.hasMode()) await this.setMode('signup');
            if (this.hasSession() && !this.checkExpiration()) {
                this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                await Promise.all([this.clearSession(), this.clearFocus()]);
            }
            await Promise.all([this.updateControls(), this.updateFactors(), this.startTimer()]);
            if (id) {
                var factor = this.filterFactors(f => f.id === id);
                if (factor.length === 0) factor.push({ id: id });
                factor[0].input = input;
                await this.selectFactor(factor[0]);
                return; // LOADING CONTROLLED BY SELECTION
            } else if (this.hasSession() && await this.postControls(false)) {
                return; // KEEP LOADING
            } else if (this.hasSession() && this.onGoal()) {
                this.consent = true;
            } else if (this.isSignup() && !this.hasSession() && this.hasControls(c => c.consent_required && c.score <= 0)) {
                this.consent = true;
            } else if ((this.isLogin() || this.hasSession()) && this.countFactors(f => f) === 1 && !prompt?.includes('login') && !error) {
                await this.selectFactor(this.filterFactors(f => f)[0]);
                return; // LOADING CONTROLLED BY SELECTION
            }
        }
        this.loading = undefined;
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            const params = new URLSearchParams(window.location.search);
            // TENANT ID
            const tenant_id = location.host.split('.')[0];
            if (ID_REGEX.test(tenant_id)) {
                this.tenant_id = tenant_id;
            } else if (params.has('tenant_id')) {
                this.tenant_id = params.get('tenant_id');
            }
            await this.client();
            // REQUEST
            if (params.has('client_id')) {
                await this.setRequest(window.location.search);
                this.client_id = params.get('client_id');
                this.client_label = params.get('client_label');
                if (params.has('scope')) this.scopes = params.get('scope').split(' ');
            } else if (this.hasRequest()) {
                const request = new URLSearchParams(this.getRequest());
                this.client_id = request.get('client_id');
                this.client_label = request.get('client_label');
                if (params.has('scope')) this.scopes = params.get('scope').split(' ');
            } else {
                this.error = 'Seems you`ve accessed this page incorrectly. We`re initiating a new request.';
                document.location.href = this.isRoot() ? `https://account${DOMAIN}` : `https://${this.tenant_id}.account${DOMAIN}`;
                this.loading = 'Initiating Request';
            }
            // MODE
            if (params.has('mode')) {
                if (params.get('mode').includes('login')) {
                    await this.setMode('login');
                } else if (params.get('mode').includes('signup')) {
                    await this.setMode('signup');
                }
            }
            // PROMPT
            if (params.has('prompt')) {
                if (params.get('prompt').includes('login')) await this.clearSession();
            }
            // ERROR
            if (params.has('error')) {
                if (!params.has('error_id')) {
                    this.showAlert(this.getErrorMessage(params.get('error')), 'Authentication', 'danger', 5000);
                } else {
                    this.showAlert(`The system encountered an unexpected error. Please try another option or contact your administrator. You can mention this reference: ${params.get('error_id')}.`, 'Authentication', 'danger');
                }
            }
            // FACTOR
            return {
                id: params.get('id'),
                input: params.get('input'),
                prompt: params.get('prompt'),
                error: params.has('error')
            };
        },

        async client() {
            try {

                // EXCHANGE CODE
                const response = await fetch(`https://api${DOMAIN}/tenants/${this.tenant_id}`, {
                    method: 'GET',
                    headers: this.hasAuthorization() ? {
                        Authorization: this.getAuthorization(true)
                    } : {}
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const tenant = await response.json();
                    if (tenant.label) this.tenant_label = tenant.label;
                    if (tenant.logo) this.tenant_logo = tenant.logo;
                    if (tenant.color) this.tenant_color = tenant.color;
                    if (tenant.login_options) this.options = tenant.login_options;
                    // CUSTOMIZATION
                    if (tenant.color) {
                        var css = document.createElement('style');
                        var color = tinycolor(tenant.color);
                        var color_hover = color.darken().toHexString();
                        var color_disabled = color.lighten(40).toHexString();
                        css.textContent = `
                            a:not(.btn,.nav-link) {
                                color: ${tenant.color};
                            }
                            .bg-primary {
                                background-color: ${tenant.color} !important;
                            }
                            .text-primary {
                                color: ${tenant.color} !important;
                            }
                            .badge-primary {
                                background-color: ${tenant.color} !important;
                            }
                            .badge-secondary {
                                background-color: ${tenant.color} !important;
                            }
                            .btn-primary {
                                background-color: ${tenant.color} !important;
                                border-color: ${tenant.color} !important;
                            }
                            .btn-primary:not(:disabled):hover {
                                background-color: ${color_hover} !important;
                                border-color: ${color_hover} !important;
                            }
                            .btn-outline-primary:not(:hover) {
                                color: ${tenant.color} !important;
                                border-color: ${tenant.color} !important;
                            }
                            .btn-outline-primary:disabled:hover {
                                color: ${tenant.color} !important;
                                border-color: ${tenant.color} !important;
                            }
                            .btn-outline-primary:not(:disabled):hover {
                                background-color: ${tenant.color} !important;
                                border-color: ${tenant.color} !important;
                            }
                            .custom-control-input:checked~.custom-control-label:before {
                                border-color: ${tenant.color} !important;
                                background-color: ${tenant.color} !important;
                            }
                            .custom-control-input:disabled:checked~.custom-control-label:before {
                                background-color: ${color_disabled} !important;
                            }
                            .active {
                                background-color: ${tenant.color} !important;
                            }
                            .active:hover {
                                background-color: ${color_hover} !important;
                            }
                            .progress-bar {
                                background-color: ${tenant.color} !important
                            }
                        `;
                        document.head.appendChild(css);
                    }
                } else {
                    this.showAlert('Failed to obtain tenant details.', 'Initialization', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to obtain tenant details.', 'Initialization', 'danger');
            }
        },

        /**
         * REQUEST
         */
        hasRequest() {
            return this.getRequest() !== null;
        },

        getRequest() {
            return localStorage.getItem(`${this.tenant_id}#REQUEST`);
        },

        async setRequest(request) {
            localStorage.setItem(`${this.tenant_id}#REQUEST`, request);
        },

        async clearRequest() {
            localStorage.removeItem(`${this.tenant_id}#REQUEST`);
        },

        /**
         * AUTHORIZATION
         */
        getAuthorization(is_public) {
            if (!is_public && this.hasSession()) {
                return `Bearer ${this.getSession()}`;
            } else if (BASIC_AUTHZ) {
                return `Basic ${BASIC_AUTHZ}`;
            } else {
                return undefined;
            }
        },

        hasAuthorization() {
            return !!BASIC_AUTHZ;
        },

        /**
         * CONTROLS
         */
        async updateControls() {
            // MAKE REQUEST
            try {
                const response = await axios({
                    method: 'GET',
                    url: `https://${this.tenant_id}.api${DOMAIN}/controls`,
                    params: {
                        client_id: this.client_id
                    },
                    headers: {
                        Authorization: this.getAuthorization(true)
                    },
                    validateStatus: status => true // NO EXCEPTIONS
                });
                // PROCESS RESPONSE
                switch (response?.status) {
                    // SUCCESS
                    case 200:
                        // FILTER SCOPES
                        this.controls = response.data.filter(c => c.subtype === 'legal' || (c.subtype === 'scope' && (c.required || this.scopes.includes(c.value))));
                        await this.updateGoal();
                        return;
                    // UNAUTHORIZED
                    case 403:
                        if (this.hasSession()) {
                            this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                            await this.resetMode();
                            break;
                        }
                    // BAD REQUEST
                    case 400:
                    default:
                        this.showAlert(response.data?.error || 'Unknown error.', 'System', 'danger');
                }
            } catch (error) {
                this.error = 'Something went wrong. We apologize for the inconvenience. Please try again later.';
                this.showAlert('Server connection failure.', 'System', 'danger');
            }
        },

        filterControls(filter) {
            return this.controls.filter(filter);
        },

        hasControls(filter) {
            return this.controls.some(filter);
        },

        async postControls(direct) {
            this.loading = 'Requesting Access';
            // MAKE REQUEST
            try {
                const response = await axios({
                    method: 'POST',
                    url: `https://${this.tenant_id}.api${DOMAIN}/controls`,
                    params: {
                        client_id: this.client_id
                    },
                    data: this.controls,
                    headers: {
                        Authorization: this.getAuthorization()
                    },
                    validateStatus: status => true // NO EXCEPTIONS
                });
                // PROCESS RESPONSE
                switch (response?.status) {
                    // SUCCESS
                    case 200:
                        switch (response.data.result) {
                            case 'SUCCESS':
                                this.showAlert(`Access granted (${this.client_label || this.client_id}).`, 'Authorization', 'success', 5000);
                                var authorization_url = `https://${this.tenant_id}.api${DOMAIN}/oauth2/authorize${this.getRequest()}&consent=${response.data.consent_token}`;
                                await Promise.all([this.setSession(response.data.session_token, response.data.session_score, response.data.session_exp, response.data.account_id), this.setMode('login'), this.clearRequest()]);
                                document.location.href = authorization_url;
                                this.loading = `Redirecting to ${this.client_label || this.client_id}`;
                                return true;
                            case 'PENDING':
                                // FILTER SCOPES
                                this.showAlert(`Access pending (${this.client_label || this.client_id}).`, 'Authorization', 'warning', 5000);
                                this.controls = response.data.controls.filter(c => c.subtype === 'legal' || (c.subtype === 'scope' && (c.required || this.scopes.includes(c.value))));
                                await this.updateGoal();
                                if (!this.hasSession()) await this.setSession(response.data.session_token, response.data.session_score, response.data.session_exp, response.data.account_id);
                                this.consent = false;
                                if (direct) this.loading = undefined;
                                return false;
                            default:
                                // FILTER SCOPES
                                this.showAlert(`Access denied (${this.client_label || this.client_id}).`, 'Authorization', 'danger', 5000);
                                this.controls = response.data.controls.filter(c => c.subtype === 'legal' || (c.subtype === 'scope' && (c.required || this.scopes.includes(c.value))));
                                await this.updateGoal();
                                if (direct) this.loading = undefined;
                                return false;
                        }
                    // UNAUTHORIZED
                    case 403:
                        if (this.hasSession()) {
                            this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                            await this.resetMode();
                            break;
                        }
                    // BAD REQUEST
                    case 400:
                    default:
                        this.showAlert(response.data?.error || 'Unknown error.', 'System', 'danger');
                }
            } catch (error) {
                this.error = 'Something went wrong. We apologize for the inconvenience. Please try again later.';
                this.showAlert('Server connection failure.', 'System', 'danger');
            }
            if (direct) this.loading = undefined;
            return false; 
        },

        /**
         * FACTORS
         */
         async updateFactors() {
            // MAKE REQUEST
            try {
                const response = await axios({
                    method: 'GET',
                    url: `https://${this.tenant_id}.api${DOMAIN}/factors`,
                    headers: {
                        Authorization: this.getAuthorization()
                    },
                    validateStatus: status => true // NO EXCEPTIONS
                });
                // PROCESS RESPONSE
                switch (response?.status) {
                    // SUCCESS
                    case 200:
                        if (this.hasSession()) {
                            this.factors = response.data; // FACTORS ARE PRE-FILTERED
                        } else {
                            this.factors = this.isLogin() ? response.data.filter(f => f.unique) : response.data.filter(f => f.unique && !f.internal);
                        }
                        return;
                    // UNAUTHORIZED
                    case 403:
                        if (this.hasSession()) {
                            this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                            await this.resetMode();
                            break;
                        }
                    // BAD REQUEST
                    case 400:
                    default:
                        this.showAlert(response.data?.error || 'Unknown error.', 'System', 'danger');
                }
            } catch (error) {
                this.error = 'Something went wrong. We apologize for the inconvenience. Please try again later.';
                this.showAlert('Server connection failure.', 'System', 'danger');
            }
        },

        filterFactors(filter) {
            if (this.onGoal()) return [];
            const factors = this.hasFocus() ? [ this.factor ] : this.factors;
            return factors.filter(filter).sort((a,b) => b.score - a.score);
        },

        hasFactors(filter) {
            if (this.onGoal()) return [];
            const factors = this.hasFocus() ? [ this.factor ] : this.factors;
            return factors.some(filter);
        },

        countFactors(filter) {
            if (this.onGoal()) return 0;
            if (this.hasFocus()) return 1;
            return this.factors.filter(filter).length;
        },

        isEnabled(factor) {
            switch (factor.status) {
                case 'ENABLED':
                    return true;
                case 'PENDING':
                    return this.isSignup();
                default:
                    return false;
            }
        },

        getVariant(factor) {
            switch (factor.status) {
                case 'LOCKED':
                    return 'danger';
                case 'DISABLED':
                    return 'secondary';
                default:
                    return 'primary';
            }
        },

        getScoreVariant(score) {
            return this.reachesGoal(score) ? 'success' : 'primary';
        },

        reachesGoal(score) {
            return (score + this.getScore()) >= this.goal;
        },

        getInfo(factor) {
            switch (factor.status) {
                case 'LOCKED':
                    return { title: 'This option is locked', content: 'If caused by several failed or non-completed attempts it will auto-unlock after 5 minutes.' };
                case 'DISABLED':
                    return { title: 'This option is disabled', content: 'You can re-enable it after login.' };
                case 'PENDING':
                    return { title: 'This option is pending', content: 'You can complete its setup after login.' };
                default:
                    return undefined;
            }
        },

        async setFocus(factor) {
            this.factor = factor;
            if (factor.expires_at) {
                const expires_at_epoch = new Date(factor.expires_at).getTime();
                if (!this.hasSession() || (this.getExpiration() > expires_at_epoch)) return this.startTimer(expires_at_epoch);
            }
        },

        async clearFocus(timer) {
            if (this.factor?.input) this.factor.input = undefined;
            if (timer && this.factor?.expires_at) {
                this.factor = undefined;
                this.output = undefined;
                return this.startTimer();
            } else {
                this.factor = undefined;
                this.output = undefined;
            }
        },

        hasFocus() {
            return !!this.factor && !this.onGoal();
        },

        getAutoComplete(factor) {
            switch (factor.subtype) {
                case 'secret:password':
                    return this.isLogin() ? 'current-password' : 'new-password';
                case 'secret:id':
                    if (factor.label.toLowerCase().includes('email')) return 'email';
                    if (factor.label.toLowerCase().includes('phone')) return 'tel';
                    return this.isLogin() ? 'username' : 'off';
                case 'otp':
                    if (factor.type !== 'enrollment') {
                        if (factor.label.toLowerCase().includes('email')) return 'email';
                        if (factor.label.toLowerCase().includes('phone')) return 'tel';
                        return 'text';
                    }
                case 'totp':
                    return 'one-time-code';
                default:
                    return 'off';
            }
        },

        getInputType(factor) {
            switch (factor.subtype) {
                case 'secret:password':
                    return 'password';
                case 'secret:id':
                    if (factor.label.toLowerCase().includes('email')) return 'email';
                    if (factor.label.toLowerCase().includes('phone')) return 'tel';
                    return 'text';
                case 'otp':
                    if (factor.type !== 'enrollment') {
                        if (factor.label.toLowerCase().includes('email')) return 'email';
                        if (factor.label.toLowerCase().includes('phone')) return 'tel';
                    }
                case 'totp':
                default:
                    return 'text';
            }
        },

        getInputName(factor) {
            switch (factor.subtype) {
                case 'secret:password':
                    return 'password';
                case 'secret:id':
                    if (factor.label.toLowerCase().includes('email')) return 'email';
                    if (factor.label.toLowerCase().includes('phone')) return 'phone';
                    return 'username';
                case 'otp':
                    if (factor.type !== 'enrollment') {
                        if (factor.label.toLowerCase().includes('email')) return 'email';
                        if (factor.label.toLowerCase().includes('phone')) return 'phone';
                    }
                case 'totp':
                    return 'one-time-code';
                default:
                    return 'name';
            }
        },

        checkInput(factor) {
            if (factor.subtype === 'jwt:spki' || factor.subtype === 'jwt:bearer' ||
                (factor.subtype === 'jwt:jwks' && factor.type === 'enrollment')) {
                return factor.input ? !!factor.input.name : null; // FILE INPUT
            } else {
                return factor.input ? new RegExp(factor.regex).test(factor.input) : null;
            }
        },

        async selectFactor(factor, generate) {
            this.loading = this.isLogin() ? 'Logging In' : 'Signing Up';
            // PERSONAL TOKEN
            if (this.isSignup() && factor.subtype === 'jwt:bearer') {
                generate = true;
            }
            // CHECK INPUT
            if (generate) {
                factor.input = undefined;
            } else if (factor.regex && !factor.input) {
                await this.setFocus(factor);
                this.loading = undefined;
                return;
            }
            // MODIFY INPUT
            if (factor.subtype?.startsWith('jwt')) {
                if (factor.input?.name) {
                    const reader = new FileReader();
                    reader.onerror = () => {
                        this.showAlert('Failed to read file.', 'Authentication', 'danger', 5000);
                        this.loading = undefined;
                    };
                    reader.onload = async () => {
                        factor.input = reader.result;
                        await this.selectFactor(factor);
                    };
                    reader.readAsText(factor.input);
                    return; // KEEP LOADING
                } else if (factor.type === 'enrollment' && !factor.subtype.endsWith('bearer')) {
                    factor.input = await new SignJWT().
                        setProtectedHeader({ alg: 'RS256' }).
                        setSubject(this.getAccount() || factor.account).
                        setIssuer(this.getAccount() || factor.account).
                        setAudience(`https://${this.tenant_id}.api${DOMAIN}/oauth2/token`).
                        setExpirationTime('5m').
                        sign(await importPKCS8(factor.input, 'RS256'));
                }
            }
            // MAKE REQUEST
            try {
                const response = await axios({
                    method: 'POST',
                    url: `https://${this.tenant_id}.api${DOMAIN}/factors`,
                    headers: {
                        Authorization: this.getAuthorization()
                    },
                    data: [{
                        id: factor.id,
                        input: factor.input
                    }],
                    validateStatus: status => true // NO EXCEPTIONS
                });
                // PROCESS RESPONSE
                switch (response?.status) {
                    // SUCCESS
                    case 200:
                        switch (response.data.result) {
                            case 'SUCCESS':
                                this.showAlert(`${this.isLogin() ? 'Login' : 'Signup'} succeeded (${factor.label || factor.id}).`, 'Authentication', 'success', 5000);
                                await Promise.all([this.setSession(response.data.session_token, response.data.session_score, response.data.session_exp, response.data.account_id), this.clearFocus()]);
                                // GENERATED OUTPUT
                                if (response.data.feedback.output) {
                                    this.output = { value: response.data.feedback.output, factor: factor };
                                } else if (await this.postControls(false)) {
                                    return; // KEEP LOADING
                                } else if (this.onGoal()) {
                                    this.consent = true;
                                } else {
                                    await this.updateFactors();
                                    // AUTO-CONTINUE IF ONLY ONE FACTOR
                                    if (this.countFactors(f => f) === 1) return this.selectFactor(this.filterFactors(f => f)[0]);;
                                }
                                break;
                            case 'PENDING':
                                this.showAlert(`${this.isLogin() ? 'Login' : 'Signup'} pending (${factor.label || factor.id}).`, 'Authentication', 'warning', 5000);
                                factor.input = undefined;
                                const enrollment = {
                                    type: 'enrollment',
                                    status: this.isLogin() ? 'ENABLED' : 'PENDING',
                                    // FACTOR
                                    subtype: factor.subtype,
                                    score: factor.score,
                                    label: factor.label,
                                    // FEEDBACK
                                    id: response.data.feedback.enrollment_id,
                                    regex: response.data.feedback.regex,
                                    account: response.data.feedback.account_id,
                                    expires_at: response.data.feedback.expires_at
                                };
                                if (enrollment.subtype === 'totp') {
                                    response.data.feedback.output = {
                                        image: await QR.toDataURL(response.data.feedback.initialization_url),
                                        secret: response.data.feedback.secret
                                    };
                                } else if (enrollment.subtype.startsWith('oauth2')) {
                                    document.location.href = response.data.feedback.authorization_url;
                                    this.loading = `Redirecting to ${this.getProviderName(enrollment.subtype.split(':')[1])}`;
                                    return; // KEEP LOADING
                                }
                                await this.setFocus(enrollment);
                                // GENERATED OUTPUT
                                if (response.data.feedback.output) {
                                    this.output = { value: response.data.feedback.output, factor: factor };
                                }
                                break;
                            case 'FAILED':
                                this.showAlert(this.getErrorMessage(response.data.feedback.cause), 'Authentication', 'danger', 5000);
                        }
                        break;
                    // UNAUTHORIZED
                    case 403:
                        if (this.hasSession()) {
                            this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                            await this.resetMode();
                            break;
                        }
                    // BAD REQUEST
                    case 400:
                        this.showAlert(`This option has been disabled or locked (${factor.label || factor.id}).`, 'System', 'danger', 5000);
                        await Promise.all([this.clearFocus(), this.updateFactors()]);
                        break;
                    default:
                        this.showAlert(response.data?.error || 'Unknown error.', 'System', 'danger');
                }
            } catch (error) {
                this.error = 'Something went wrong. We apologize for the inconvenience. Please try again later.';
                this.showAlert('Server connection failure.', 'System', 'danger');
            }
            this.loading = undefined;
        },

        async saveOutput() {
            this.loading = 'Processing';
            // Save output
            if (this.output.factor.subtype.startsWith('jwt')) {
                // Prepare files
                const files = [];
                if (this.output.factor.subtype === 'jwt:spki') {
                    const outputs = this.output.value.split(';');
                    files.push(new File([outputs[0]], 'private_key.pem', { type: 'application/x-pem-file' }));
                    files.push(new File([outputs[1]], 'public_key.pem', { type: 'application/x-pem-file' }));
                } else {
                    files.push(new File([this.output.value], 'personal_token.jwt', { type: 'application/jwt' }))
                }
                // Download files
                for (const file of files) {
                    const link = document.createElement('a');
                    const url = URL.createObjectURL(file);
                    // Trigger download
                    link.href = url;
                    link.download = file.name;
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    // Cleanup download
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                    this.showAlert(`P${file.name.split('.')[0].replace('_',' ').slice(1)} downloaded to file.`, 'Output', 'success', 5000);
                }
            } else {
                // Copy to clipboard
                await navigator.clipboard.writeText(this.output.value);
                this.showAlert(`${this.output.factor.label} copied to clipboard.`, 'Output', 'success', 5000);
            }
            // Clear output
            return this.clearOutput(); // KEEP LOADING
        },

        async clearOutput() {
            this.loading = 'Processing';
            // Clear output
            this.output = undefined;
            // Continue flow
            if (!this.hasFocus()) {
                if (await this.postControls(false)) {
                    return; // KEEP LOADING
                } else if (this.onGoal()) {
                    this.consent = true;
                } else {
                    await this.updateFactors();
                    // AUTO-CONTINUE IF ONLY ONE FACTOR
                    if (this.countFactors(f => f) === 1) return this.selectFactor(this.filterFactors(f => f)[0]);;
                }
            }
            this.loading = undefined;
        },

        /**
         * SESSION
         */
        getSession() {
            return localStorage.getItem(`${this.tenant_id}#SESSION`);
        },

        getScore() {
            return parseInt(localStorage.getItem(`${this.tenant_id}#SCORE`)) || 0;
        },

        getAccount() {
            return localStorage.getItem(`${this.tenant_id}#ACCOUNT`);
        },

        onGoal() {
            return this.getScore() >= this.goal;
        },

        async updateGoal() {
            this.goal = Math.max(...this.controls.map(c => c.score));
        },

        hasSession() {
            return this.getSession() !== null;
        },

        async setSession(session, score, expiration, account) {
            if (!session) return;
            localStorage.setItem(`${this.tenant_id}#SESSION`, session);
            localStorage.setItem(`${this.tenant_id}#SCORE`, score);
            localStorage.setItem(`${this.tenant_id}#EXPIRATION`, expiration);
            localStorage.setItem(`${this.tenant_id}#ACCOUNT`, account);
            return this.startTimer();
        },

        async clearSession() {
            localStorage.removeItem(`${this.tenant_id}#SESSION`);
            localStorage.removeItem(`${this.tenant_id}#SCORE`);
            localStorage.removeItem(`${this.tenant_id}#EXPIRATION`);
            localStorage.removeItem(`${this.tenant_id}#ACCOUNT`);
            return this.clearTimer();
        },

        getExpiration() {
            return parseInt(localStorage.getItem(`${this.tenant_id}#EXPIRATION`)) * 1000;
        },

        checkExpiration() {
            return (new Date().getTime()) < this.getExpiration();
        },

        /**
         * CANCEL
         */
        async cancel() {
            this.loading = `Redirecting to ${this.client_label || this.client_id}`;
            var authorization_url = `https://${this.tenant_id}.api${DOMAIN}/oauth2/authorize${this.getRequest()}&error=access_denied`;
            await this.clearRequest();
            document.location.href = authorization_url;
            return true;
        },

        /**
         * INVITE
         */
        checkToken() {
            return this.token ? new RegExp('^[\\w-]*\\.[\\w-]*\\.[\\w-]*$').test(this.token) : null;
        },

        validateToken(payload) {
            if (payload.iss !== `https://${this.tenant_id}.api${DOMAIN}`) return false;
            if (payload.scope !== `https://api${DOMAIN}/scopes/signup`) return false;
            if (payload[`https://api${DOMAIN}/claims/scr`] !== 0) return false;
            return true;
        },

        async useToken() {
            this.loading = 'Checking';
            try {
                var payload = decodeJwt(this.token);
                if (this.validateToken(payload)) {
                    this.showAlert('Invite token accepted.', 'Authentication', 'success', 5000);
                    await this.setSession(this.token, 0, payload.exp, payload.sub);
                    if (await this.postControls(false)) {
                        return; // KEEP LOADING
                    } else if (this.hasControls(c => c.consent_required && c.score <= 0)) {
                        this.consent = true;
                    } else {
                        this.consent = false;
                        await this.updateFactors();
                        // AUTO-CONTINUE IF ONLY ONE FACTOR
                        if (this.countFactors(f => f) === 1) {
                            this.token = undefined;
                            return this.selectFactor(this.filterFactors(f => f)[0]);
                        }
                    }
                } else {
                    this.showAlert('Invalid invite token', 'Authentication', 'danger', 5000);
                }
            } catch (error) {
                this.showAlert('Invalid invite token: ' + error, 'Authentication', 'danger', 5000);
            } finally {
                this.token = undefined;
                this.loading = undefined;
            }
        },

        /**
         * MODE
         */
        isLogin() {
            return this.getMode() === 'login';
        },

        isSignup() {
            return this.getMode() === 'signup';
        },

        getMode() {
            return localStorage.getItem(`${this.tenant_id}#MODE`);
        },

        hasMode() {
            return this.getMode() !== null;
        },

        async setMode(mode) {
            localStorage.setItem(`${this.tenant_id}#MODE`, mode);
        },

        async resetMode(mode) {
            this.loading = 'Updating';
            this.output = undefined;
            await Promise.all([this.clearSession(), this.clearFocus()]);
            if (mode) await this.setMode(mode);
            await Promise.all([this.updateControls(), this.updateFactors()]);
            this.consent = this.isSignup() && this.hasControls(c => c.consent_required && c.score <= 0);
            this.loading = undefined;
        },

        async switchMode() {
            return this.resetMode(this.isLogin() ? 'signup' : 'login');
        },

        /**
         * ALERT
         */
        async showAlert(message, title, variant, delay) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                autoHideDelay: delay,
                noAutoHide: !delay
            });
        },

        /**
         * FIlTER
         * 
         * See: https://codepen.io/sosuke/pen/Pjoqqp
         */
        getFilter(variant) {
            switch (variant) {
                case 'primary':
                    // return 'invert(48%) sepia(15%) saturate(3187%) hue-rotate(183deg) brightness(89%) contrast(89%)';
                    return hexToCSSFilter(this.tenant_color).filter;
                case 'secondary':
                    // return 'invert(45%) sepia(6%) saturate(672%) hue-rotate(167deg) brightness(98%) contrast(88%)';
                    return hexToCSSFilter('#6c757d').filter; // GREY-600
                case 'success':
                    // return 'invert(52%) sepia(23%) saturate(1324%) hue-rotate(81deg) brightness(96%) contrast(94%)';
                    return hexToCSSFilter('#28a745').filter; // GREEN
                case 'info':
                    // return 'invert(69%) sepia(11%) saturate(4319%) hue-rotate(140deg) brightness(77%) contrast(83%)';
                    return hexToCSSFilter('#17a2b8').filter; // CYAN
                case 'warning':
                    // return 'invert(78%) sepia(84%) saturate(2275%) hue-rotate(355deg) brightness(101%) contrast(102%)';
                    return hexToCSSFilter('#ffc107').filter; // YELLOW
                case 'danger':
                    // return 'invert(33%) sepia(100%) saturate(897%) hue-rotate(320deg) brightness(85%) contrast(107%)';
                    return hexToCSSFilter('#dc3545').filter; // RED
                case 'light':
                    // return 'invert(98%) sepia(3%) saturate(517%) hue-rotate(97deg) brightness(106%) contrast(94%)';
                    return hexToCSSFilter('#f8f9fa').filter; // GRAY-100
                case 'white':
                    // return 'invert(100%) sepia(94%) saturate(0%) hue-rotate(227deg) brightness(105%) contrast(105%)';
                    return 'invert(100%) sepia(94%) saturate(0%) hue-rotate(227deg) brightness(10000%) contrast(200%)';
                    // return hexToCSSFilter('#ffffff').filter; // WHITE
                default: // DARK
                    // return 'invert(19%) sepia(8%) saturate(952%) hue-rotate(169deg) brightness(91%) contrast(85%)';
                    return hexToCSSFilter('#343a40').filter; // GRAY-800
            }
        },

        /**
         * SYSTEM
         */
        getWebsite() {
            return `https://www${DOMAIN}`;
        },

        getRelease() {
            return this.isProduction() ? UPDATE_DATE : `${UPDATE_DATE}-${ENVIRONMENT}`;
        },

        isRoot() {
            return this.tenant_id === ROOT_TENANT;
        },

        isProduction() {
            return ENVIRONMENT === 'prod';
        },

        getErrorMessage(error) {
            switch(error) {
                case 'MISSING_INPUT':
                    return 'Please provide input.';
                case 'INVALID_INPUT':
                    return 'This input can\'t be used.';
                case 'RESERVED_INPUT':
                    return 'This input is already taken.';
                case 'INCORRECT_INPUT':
                    return 'This input is not correct.';
                case 'ENROLLMENT_NOT_FOUND':
                    return 'This user is not yet registered.';
                case 'ENROLLMENT_ALREADY_EXISTS':
                    return 'This user is already registered.';
                case 'ENROLLMENT_MISMATCH':
                    return 'This user is not the correct one.';
                case 'REJECTED_LOGIN':
                    return 'The identity provider indicated login was rejected.';
                default:
                    return 'The system encountered an unexpected error. Please try another option or contact your administrator.'
            }
        },

        getProviderName(subtype) {
            switch (subtype) {
                case 'linkedin':
                    return 'LinkedIn';
                case 'github':
                    return 'GitHub';
                case 'oidc':
                    return 'Identity Provider';
                default:
                    return subtype.charAt(0).toUpperCase() + subtype.slice(1);
            }
        },

        /**
         * TIMER
         */
        async startTimer(end) {
            await this.clearTimer();
            if (end) {
                return this.setTimer(end, false);
            } else if (this.hasSession()) {
                return this.setTimer(this.getExpiration(), true);
            }
        },
        
        async setTimer(end, session) {
            const now = new Date().getTime();
            if (now < end) {
                var diff = end - now;
                this.timer.hours = Math.floor(diff / 1000 / 60 / 60);
                diff -= this.timer.hours * 1000 * 60 * 60;
                this.timer.minutes = Math.floor(diff / 1000 / 60);
                diff -= this.timer.minutes * 1000 * 60;
                this.timer.seconds = Math.floor(diff / 1000);
                this.timer.timeout = setTimeout(this.setTimer, 1000, end, session);
            } else {
                if (session) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    return this.resetMode();
                } else {
                    this.showAlert('Your window has expired.', 'Authentication', 'warning', 5000);
                    return this.clearFocus(true);
                }
            }
        },

        async clearTimer() {
            if (this.timer.timeout) clearTimeout(this.timer.timeout);
            this.timer = { days: 0, hours: 0, minutes: 0, seconds: 0, timeout: undefined };
        }

    }

}
</script>
